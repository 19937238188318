var savePanel = {
    divId: 'savePanel',
    spinnerDivId: 'savePanelSpinnerDiv',
    spinnerImage: SPINNERIMAGE,
    saveButtonId: 'savePanelSaveButton',
    cancelButtonId: 'savePanelCancelButton',
    submittingMessageId: 'savePanelSubmittingMessage',
    onEditFunction: null,
    onSaveFunction: null,
    onCloseFunction: null,
    onCancelFunction: null,
    onDoneFunction: null,
    anyButtonsVisible: false,
    saveButtonVisible: false,
    saveName: 'Save',
    isOpen: false,
    open: function (content, extraOptions) {
        this.enableHotKeys();
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        dynamic.showOverlay('savePanelOverlay', 8);
        dynamic.setDivIdContent('savePanelContent', content);
        if (options.beforeShow != null) {
            options.beforeShow();
        }
        content = '';
        this.anyButtonsVisible = false;
        if (options.showCancel == null || options.showCancel == true) {
            this.anyButtonsVisible = true;
            content += tags.a('Cancel', 'javascript:savePanel.cancelClicked()', {
                cssClass: 'button cancel',
                id: this.cancelButtonId
            });
        }
        if (options.showSave == null || options.showSave == true) {
            this.saveButtonVisible = true;
            this.anyButtonsVisible = true;
            if (options.saveName != null) {
                this.saveName = options.saveName = options.saveName;
            }
            content += tags.a(this.saveName, 'javascript:savePanel.saveClicked()', {
                cssClass: 'button save',
                id: this.saveButtonId
            });
        }
        if (options.showEdit == true) {
            this.anyButtonsVisible = true;
            content += tags.a('Edit', 'javascript:savePanel.editClicked()', {
                cssClass: 'button edit'
            });
        }
        if (options.showDone != null && options.showDone == true) {
            this.anyButtonsVisible = true;
            content += tags.a('Done', 'javascript:savePanel.doneClicked()', {
                cssClass: 'button done'
            });
        }
        content += tags.div('', {
            id: this.spinnerDivId
        });
        content += tags.div('Submitting...', {
            id: this.submittingMessageId,
            style: 'display:none'
        });
        dynamic.setDivIdContent('savePanelButtons', content);
        if (this.anyButtonsVisible) {
            dynamic.removeClass('savePanelButtons', 'hideIt');
        } else {
            dynamic.addClass('savePanelButtons', 'hideIt');
        }

        // Show by default.
        if (options.showClose === false) {
            dynamic.addClass('closeButton', 'hideIt');
        }

        this.onSaveFunction = null;
        this.onEditFunction = null;
        this.onCloseFunction = null;
        this.onCancelFunction = null;
        this.onDoneFunction = null;
        this.saveButtonDisabledText = 'Saving...';
        if (options.onsave != null) {
            this.onSaveFunction = options.onsave;
        }
        if (options.onedit != null) {
            this.onEditFunction = options.onedit;
        }
        if (options.onclose != null) {
            this.onCloseFunction = options.onclose;
        }
        if (options.oncancel != null) {
            this.onCancelFunction = options.oncancel;
        }
        if (options.ondone != null) {
            this.onDoneFunction = options.ondone;
        }
        if (options.saveButtonDisabledText != null) {
            this.saveButtonDisabledText = options.saveButtonDisabledText;
        }
        var scrollTop = $(window).scrollTop();
        if (options.fixed == true) {
            scrollTop = 0;
            $('#' + this.divId).css('position', 'fixed');
        }
        var topOffset = 100;
        if (options.topOffset != null) {
            topOffset = options.topOffset;
        }
        var top = scrollTop + topOffset;
        var width = '500px';
        if (options.width != null) {
            width = options.width;
        }
        $('#' + this.divId).css({
            width: width
        });
        dynamic.centerDiv(this.divId);
        $('#' + this.divId).css({
            top: top + 'px'
        });
        $('#' + this.divId).fadeIn(null, function () {
            if (options.afterShow != null) {
                options.afterShow();
            }
        });
        this.isOpen = true;
    },
    close: function () {
        if (this.onCloseFunction != null) {
            this.onCloseFunction();
        }
        this.onclose();
        $('#' + this.divId).fadeOut(null, function () {
            dynamic.setDivIdContent('savePanelContent', '');
        });
        dynamic.hideOverlay('savePanelOverlay');
        this.isOpen = false;
    },
    enableHotKeys: function () {
        document.onkeydown = function (e) {
            if (savePanel.saveButtonVisible && e.metaKey && e.which == 83) {
                savePanel.saveClicked();
                return false;
            }

            if (e.which == 27) {
                savePanel.close();
                return false;
            }
        };
    },
    disableHotKeys: function () {
        document.onkeydown = null;
    },
    onclose: function () {
        this.disableHotKeys();
        this.disableSaveButton(false);

        // event listeners
    },
    showSpinner: function () {
        dynamic.setDivIdContent(savePanel.spinnerDivId, '<span class="preloader"><img src="' + this.spinnerImage + '"/></span>');
    },
    hideSpinner: function () {
        dynamic.setDivIdContent(savePanel.spinnerDivId, '');
    },
    saveClicked: function () {
        if (!$('#' + this.saveButtonId).hasClass('clicked')) {
            this.disableSaveButton(true);
            this.onsave();
            if (this.onSaveFunction != null) {
                this.disableHotKeys();
                this.onSaveFunction(this.saveComplete.bind(this));
            }
        }
    },
    onsave: function () {
        // event listeners
    },
    disableSaveButton: function (isSaving) {
        var saveButton = $('#' + this.saveButtonId);
        saveButton.addClass('clicked');
        saveButton.html(isSaving ? this.saveButtonDisabledText : this.saveName);
    },
    reEnableSaveButton: function () {
        var saveButton = $('#' + this.saveButtonId);
        var that = this;
        if (saveButton) {
            setTimeout(function () {
                saveButton.removeClass('clicked');
                saveButton.html(that.saveName);
            }, 1000);
        }
    },
    saveComplete: function () {
        var saveButton = $('#' + this.saveButtonId);
        saveButton.removeClass('clicked');
        saveButton.html(this.saveName);
    },
    editClicked: function () {
        this.onedit();
        if (this.onEditFunction != null) {
            this.onEditFunction();
        } else {
            this.close();
        }
    },
    onedit: function () {
        // event listeners
    },
    cancelClicked: function () {
        this.ondone();
        if (this.onCancelFunction != null) {
            this.onCancelFunction();
        } else {
            this.close();
        }
    },
    ondone: function () {
        // event listeners
    },
    doneClicked: function () {
        this.ondone();
        if (this.onDoneFunction != null) {
            this.onDoneFunction();
        } else {
            this.close();
        }
    }
};
